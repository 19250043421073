import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';

import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import Card from '../../shared/components/UIElements/Card';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';

const LeaderboardPage = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedPlayers, setLoadedPlayers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/leaderboard`);

        setLoadedPlayers(responseData.players);
      } catch (err) {}
    };
    fetchUsers();
  }, [sendRequest]);

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && loadedPlayers.length===0 && (
        <div className="center">
        <Card>
          <h2>No players found.</h2>
        </Card>
      </div>
      )}
      {!isLoading && loadedPlayers && (
        <React.Fragment>
          <Grid container style={{ minWidth: 350 }}>
            <Grid item xs={5} style={{ backgroundColor: 'lightgrey' }}>
              <div align="center"><h5>Player</h5></div>
            </Grid>
            <Grid item xs={2} style={{ backgroundColor: 'lightgrey' }}>
              <div align="center"><h5>Wins</h5></div>
            </Grid>
            <Grid item xs={2} style={{ backgroundColor: 'lightgrey' }}>
              <div align="center"><h5>Losses</h5></div>
            </Grid>
            <Grid item xs={2} style={{ backgroundColor: 'lightgrey' }}>
              <div align="right"><h5>Win %</h5></div>
            </Grid>
            {loadedPlayers.map(player => (
              <React.Fragment>
                <Grid item xs={5}>
                  <div align="left"><h5>{player.player?.username}</h5></div>
                </Grid>
                <Grid item xs={2}>
                  <div align="center"><h5>{player.wins}</h5></div>
                </Grid>
                <Grid item xs={2}>
                  <div align="center"><h5>{player.losses}</h5></div>
                </Grid>
                <Grid item xs={2}>
                  <div align="right"><h5>{player.winpercent.toFixed(1)}</h5></div>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default LeaderboardPage;
