
import {wagerArbInitiated,  wagerSelfArbitrated, wagerInplay, wagerJudge, 
  wagerContested, wagerInvited, wagerValidate, wagerRejected} from '../../wagerStates'
import { timeFormatter } from '../../shared/helpers/formatters'

export const wagerOpponent = (auth, creator, opponent) => {
  if(creator===undefined){
    return ""
  }

  let results = opponent;
  if(auth.username===opponent){
    results = creator;
  }

  return results;
}

export const calculateYesNo = (wager) => {
  let yes = wager.creator;
  let no = wager.opponent;
  if(wager.yesplayer===no.id){
    yes = wager.opponent;
    no = wager.creator;
  }
  
  return [yes, no];
}
export const resolveGameAction = (auth, wager) => {
  let action = "";
  if(wager!==undefined){
    if(wager.status===wagerInvited){
      action = "Invite Pending";
    } else if(wager.status===wagerArbInitiated){
      action = "Arbitration Pending";
    } else if(wager.status===wagerInplay){
      action = "In Play";
    } else if(wager.arbitrated){
      if(wager.game.house){
        action = "Event Arbitrated!"
      } else {
        action = wager.arbitrationwinner===auth.userId? "Arbitration Won":"Arbitration Lost";
      }
    }
  }

  return action;
}

export const resolveBetStatus = (auth, wager) => {

  if(wager.status===undefined){
    return ["","",""]
  }

  let results = wager.status;
  let color = "Pink";
  let backgroundcolor = "white";
  
  if(results === wagerInvited){
    if(auth.username===wager.creator.username){
      results = "SENT";
      color = "Black";
    } else {
      results = "INVITE";
    }
    backgroundcolor = "#eee";
  }

  if(results === wagerArbInitiated || results === wagerInplay){
    color="Black";
  }
  
  if(wager.arbitrated){
    let arbResults = "LOST";
    if(wager.game.house){
      arbResults="ARB'D";
    } else {
      color = "Red";
      if(wager.arbitrationwinner=== auth.userId){
        arbResults = "WON"
        color = "Green";
      } 
    }

    results = arbResults;
  }
 
  return [results,color,backgroundcolor];
}

export function generateWagerStatus(localWager, gameStatus, userId){
    let wagerAction = "";

    if(localWager.arbitrated ){
      const prefix = localWager.status === wagerSelfArbitrated? "Both players agreed":"";
      const suffix = localWager.arbitrationposition===undefined? "": `${localWager.arbitrationposition} is the winner`;
      wagerAction = `${localWager.arbitrationtext==='undefined'? "":localWager.arbitrationtext} \n ${prefix} ${suffix}`
    } else {
      if(gameStatus==="INVITE"){
        wagerAction = `${timeFormatter(localWager.event.wagerCloseDate)} to accept wager`;
      } else if(gameStatus==="SENT"){
        wagerAction = `${timeFormatter(localWager.event.wagerCloseDate)} for opponent to accept`;
      } else if(localWager.status === wagerInplay){
        wagerAction = `wager ends in ${timeFormatter(localWager.event.eventCloseDate)}`;
      } else if(localWager.status === wagerJudge){
        let timeToClose = localWager.initiatingenddate;
        let timeText = "to claim win or loss";
        if (timeToClose === undefined){
          timeToClose = localWager.event.eventCloseDate;
          timeText = "until judge snacks returns a decision";
        }
        wagerAction = `${timeFormatter(timeToClose)} ${timeText}`;
      } else if(localWager.status === wagerContested){
        wagerAction = `24 hours until Judge Snacks returns a decision`;
      } else if(localWager.status === wagerValidate){
        const validationMessage = localWager.validationcomments[0];
        wagerAction = `Validation requested by ${validationMessage.creatorname}: ${validationMessage.message}`;
      } else if(localWager.status === wagerRejected){
        const rejectionMessage = localWager.validationcomments.pop();
        wagerAction = `Snacks rejected this: ${rejectionMessage.message}`;
      } else if(localWager.status === wagerArbInitiated){
        let text = `Waiting on Judge Snacks to decide the winner.`;
        if(localWager.initiatingplayer !== undefined){
          text = userId===localWager.initiatingplayer ? 
          `You have claimed a ${localWager.initiatingposition} on this wager`: 
          `Your Opponent has claimed a ${localWager.initiatingposition}. Do you agree?`
        }
        wagerAction = `${text}`;
      }
    }
    return wagerAction
}

export const commentTruncator = (width, comment) => {
    let results = comment;
    if(comment.length > width){
       let arrayString = comment.split(" ");
       results = "";
       for (const item of arrayString){
        if(width >= (results.length + item.length)){
            results += ` ${item}`;
        } else {
            results += "...";
            break;
        }
       }
    }
    return results;
}

export const truncateByWords = (count, comment) => {
  let arrayString = comment.split(" ");
  let results = "";
  count = count < arrayString.length? count : arrayString.length;
  let suffix = count===arrayString.length? "":"...";
  for (let i = 0; i < count; i++) {
    results += " " + arrayString[i];
  }

  results += suffix;
  return results;
}

function createStats( suffix, arbd, wins, losses, winpercent) {
  return { suffix, arbd, wins, losses, winpercent}; 
}

export const houseGameLeaderboard = (players) => {
  const sortedPlayers = players.sort((a, b) => b.score - a.score);
  const sortedMap = new Map();

  if(sortedPlayers.length > 0){
    let rank = 1;
    let previousScore = sortedPlayers[0].score;
    sortedPlayers.forEach(player => {
      if(player.score !== previousScore){
        rank += 1;
        previousScore = player.score;
      }
      const entry = {rank: rank, score: player.score};
      sortedMap.set(player.player.username, entry);
    })
  }

  return sortedMap;
}

export const gameLeaderboard = (wagers) => {
  
  let newMap = new Map();
  wagers.forEach(wager => {
      
    if(wager.arbitrated){
      let opponent = wager.opponent.username;
      const creator = wager.creator.username;
      let opponentStats = createStats('',0,0,0,0);;
      if(newMap.has(opponent)){
        opponentStats = newMap.get(opponent);               
      } 
      let creatorStats = createStats('',0,0,0,0);;
      if(newMap.has(creator)){
        creatorStats = newMap.get(creator);               
      } 
      opponentStats.arbd += 1;
      creatorStats.arbd += 1;
      let results=wager.arbitrationwinner=== wager.opponent.id?"won":"lost";
      if (results==="lost"){
        creatorStats.wins +=1
        opponentStats.losses +=1
      } else if (results==="won"){
        opponentStats.wins +=1
        creatorStats.losses +=1
      }
      opponentStats.winpercent = Math.round(100*opponentStats.wins/opponentStats.arbd).toFixed(1);
      creatorStats.winpercent = Math.round(100*creatorStats.wins/creatorStats.arbd).toFixed(1);

      newMap.set(opponent, opponentStats);
      newMap.set(creator, creatorStats);
    } 
    
  })

  const tempMap = Array.from(newMap).sort((a, b) => b[1].winpercent - a[1].winpercent);
  const sortedMap = new Map(tempMap);
  return sortedMap;
}