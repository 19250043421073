import React , {useEffect, useContext, useState} from 'react';
import {  useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';

import BetItem from './BetItem';
import { BetContext } from '../../shared/context/bet-context';
import { AuthContext } from '../../shared/context/auth-context';
import Card from '../../shared/components/UIElements/Card';
import { setOpponentInformation, setWagerDefaults } from '../../shared/helpers/utilities';
import {wagerArbitrated} from '../../wagerStates'
import './BetsList.css';

const BetsList = ({bets, game}) => {
  const bet = useContext(BetContext);
  const auth = useContext(AuthContext);
  const [displayWagers, setDisplayWagers] = useState(bets);
  const [closedWagers, setClosedWagers] = useState([]);
  const [displayClosedWagers, setDisplayClosedWagers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if(game.house && bets.length>0){
      // display wagers that have not been arbitrated. 
      let openWagers = bets.filter(bet => bet.status!==wagerArbitrated);
      let closedWagers = bets.filter(bet => bet.status===wagerArbitrated);
      if(openWagers.length===0){
        // If no such wagers are found, display most recent wagers that have been arbitrated.
        if(closedWagers.length>0){
          const latestEventDate = dayjs(closedWagers[0].event.eventCloseDate).format('MM/DD/YYYY');
          console.log(`latest event date: ${latestEventDate}`);
          openWagers = bets.filter(bet => dayjs(bet.event.eventCloseDate).format('MM/DD/YYYY')===latestEventDate);
          closedWagers = bets.filter(bet => dayjs(bet.event.eventCloseDate).format('MM/DD/YYYY')!==latestEventDate);
        }
       }
       setDisplayWagers(openWagers);
       setClosedWagers(closedWagers);

    }
  }, [game, bets]);

  const showpastWagers = () => {
    setDisplayClosedWagers(closedWagers);
  }

  const addWager = async () =>{
    const yesPlayer = {
      id: auth.userId,
      username: auth.username,
      image: auth.userImage
    }
    bet.yesPlayer = yesPlayer;
    bet.creator = auth.userId;
    bet.betText = "";
    bet.topicId=null;
    const opponent = game.creator.username===auth.username?game.opponent:game.creator;
    setOpponentInformation(bet, opponent);
    await setWagerDefaults(auth, bet);  
    bet.gameId = game._id;
    bet.gameName = game.name;
    
    navigate('/addbettopic/');
  }

  if (displayWagers.length === 0 && !game.house) {
    return (
      <div className="center">
        <Card>
          <h4>No wagers found.</h4>
          <Button onClick={addWager} variant="contained" color='secondary'>Add Wager</Button>
        </Card>
      </div>
    );
  }
  
  return (
    <React.Fragment>
    {!game.house && (
      <Card className="center" >
        <Button  onClick={addWager} variant="contained" color='secondary'>Add Wager</Button>
      </Card>
    )}

    <ul className="bets-list">
      {displayWagers.map(bet => (
        <li key={bet.id}>
        <div onClick={() => navigate(`/viewbet/${bet._id}`)}>
        <BetItem
          key={bet.id}
          id={bet._id}
          wager={bet}
          canwager={false}
          topicTitle={bet.event===null?"":bet.event.topic.title}
          topicArea={bet.event===null?"":bet.event.topic.area}
          event={bet.event}
          text={bet.text}
          status={bet.status}
          creatorname={bet.creator.username}
          creatorimage={bet.creator.image}
          opponentname={bet.opponent.username}
          opponentimage={bet.opponent.image}
          initiator={bet.initiatingplayer}
          initiatorPosition={bet.initiatingposition}
          arbitrated={bet.arbitrated}
          arbitrationPosition={bet.arbitrationposition}
          arbitrationResults={bet.arbitrationtext}
        />
        </div>
        </li>
      ))}
    </ul>

      {closedWagers.length !== 0 && displayClosedWagers.length === 0 && (
        <Card>
          <Button onClick={showpastWagers} variant="contained" color='secondary'>Show Past Wagers</Button>
        </Card>
      )}

      {displayClosedWagers && displayClosedWagers.length !== 0 && (
        <ul className="bets-list">
          {displayClosedWagers.map(bet => (
            <li key={bet.id}>
            <div onClick={() => navigate(`/viewbet/${bet._id}`)}>
            <BetItem
              key={bet.id}
              id={bet._id}
              wager={bet}
              topicTitle={bet.event===null?"":bet.event.topic.title}
              topicArea={bet.event===null?"":bet.event.topic.area}
              event={bet.event}
              text={bet.text}
              status={bet.status}
              creatorname={bet.creator.username}
              creatorimage={bet.creator.image}
              opponentname={bet.opponent.username}
              opponentimage={bet.opponent.image}
              initiator={bet.initiatingplayer}
              initiatorPosition={bet.initiatingposition}
              arbitrated={bet.arbitrated}
              arbitrationPosition={bet.arbitrationposition}
              arbitrationResults={bet.arbitrationtext}
            />
            </div>
            </li>
          ))}
      </ul>
      )}

    </React.Fragment>
  );
};

export default BetsList;
